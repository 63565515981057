import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Create an interface for the context
interface AuthContextProps {
  login: (phoneNumber: string, password: string) => Promise<void>;
  signup: (userData: { firstName: string, lastName: string, email: string, phoneNumber: string, password: string, role: string }) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
}

// Create the context
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Create a custom hook to use the context
export const useAuth = () => useContext(AuthContext)!;

// Create the provider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/users/token-validity/`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          // Check if the response indicates the token is valid
          // This depends on your API response structure
          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Fetch error:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    checkTokenValidity();
  }, []);

  
  // Login function
  const login = async (phoneNumber: string, password: string) => {
    try {
      const response = await axios.post(`${API_URL}/users/login/`, {
        "phoneNumber": phoneNumber,
        "password": password
    });
      
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setIsAuthenticated(true);
        navigate('/');
      } else {
        throw new Error('Login failed: No token received.');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  // Signup function
  const signup = async (userData: { firstName: string, lastName: string, email: string, phoneNumber: string, password: string, role: string }) => {
    try {
      const response = await axios.post(`${API_URL}/users/signUp/`, userData);

      console.log('Signup response:', response);
      
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setIsAuthenticated(true);
        console.log('Authenticated:', isAuthenticated);
        navigate('/');
      } else {
        throw new Error('Login failed: No token received.');
      }
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  };

  // Logout function
  const logout = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${API_URL}/users/logout/`, {}, {
        headers: {
          Authorization: `Token ${token}`,
        }
      });
      localStorage.removeItem('token');      
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      // Handle error
      if (error instanceof Error) {
        console.error('Logout error:', error.message);
      } else {
        console.error('Logout error: An unknown error occurred.');
      }
    }
  };

  return (
    <AuthContext.Provider value={{ login, signup, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
