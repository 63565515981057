import React from 'react';

interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string;
  class_for: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  mode: string;
  tags: { name: string }[];
  price: string;
  discount_percentage: string;
}

interface CourseCardProps {
  course: Course;
  onEdit: (courseId: number) => void;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, onEdit }) => {
  return (
    <div className="border rounded-lg p-4 shadow-md max-w-sm">
      <img src={course.course_img} alt={course.course_name} className="w-full h-48 object-cover rounded-md" />
      <h2 className="text-xl font-bold mt-4">{course.course_name}</h2>
      <p className="text-gray-600">{course.description}</p>
      <p className="mt-2">Mode: {course.mode}</p>
      <p className="mt-2">Price: ₹{course.price}</p>
      <p className="mt-2">Discount: {course.discount_percentage}%</p>
      <p className="mt-2">Teacher: {course.teacher.user.first_name} {course.teacher.user.last_name}</p>
      <div className="flex flex-wrap mt-2">
        {course.tags.map((tag, index) => (
          <span key={index} className="bg-blue-200 text-blue-700 px-2 py-1 rounded-full text-sm mr-2">{tag.name}</span>
        ))}
      </div>
      <button
        onClick={() => onEdit(course.id)}
        className="bg-blue-500 text-white mt-4 px-4 py-2 rounded hover:bg-blue-600"
      >
        Edit Course in Detail
      </button>
    </div>
  );
};

export default CourseCard;
