import exp from "constants";
import React, { useState, useEffect } from "react";

interface Faculty {
  name: string;
  experience: string;
  title: string;
  description: string;
  image: string;
}

const facultiesData: Faculty[] = [
  {
    name: "Danish Quaisar",
    experience: "5+yr experience",
    title: "Director & PHYSICS FACULTY",
    description: "Focus on Theory plus problem Solving",
    image: "https://www.klassroom.com/cdn/shop/articles/howdoIbecomeateacherlaterinlife_691984306.jpg?v=1649211447",
  },
  {
    name: "Kazim Alam",
    experience: "5+yr experience",
    title: "Maths & Language FACULTY",
    description: "Do 3 times Syllabus cover in a year",
    image: "https://www.klassroom.com/cdn/shop/articles/howdoIbecomeateacherlaterinlife_691984306.jpg?v=1649211447",
  },
  {
    name: "Ahmad Reza",
    experience: "3+yr experience",
    title: "CHEMISTRY FACULTY",
    description: "Complete Theory Plus Problems before time.",
    image: "https://www.klassroom.com/cdn/shop/articles/howdoIbecomeateacherlaterinlife_691984306.jpg?v=1649211447",
  },
  {
    name: "Muskan",
    experience: "1+yr experience",
    title: "Foundation Batches FACULTY",
    description: "Cover Languages under 9th class",
    image: "https://www.klassroom.com/cdn/shop/articles/howdoIbecomeateacherlaterinlife_691984306.jpg?v=1649211447",
  },
];

const FacultyCard: React.FC<Faculty> = ({
  name,
  experience,
  title,
  description,
  image,
}) => {
  return (
    <div className="rounded-2xl shadow-xl p-2 bg-white min-w-60 max-w-72 mx-2 mb-4">
      <div className="w-full h-48 rounded-lg overflow-hidden">
        <img
          src={image}
          alt={name}
          className="object-cover w-full h-full rounded-xl"
        />
      </div>
      <h3 className="text-lg font-bold mt-4">{name}</h3>
      <p className="text-gray-500 text-sm mt-2">{experience}</p>
      <h4 className="text-xl font-semibold mt-4">{title}</h4>
      <p className="text-gray-600 text-sm mt-2">{description}</p>
    </div>
  );
};

const Faculties: React.FC = () => {
  const [faculties, setFaculties] = useState<Faculty[]>([]);

  useEffect(() => {
    // Fetch data from API or use static data
    setFaculties(facultiesData);
  }, []);

  return (
    <div className="container mx-auto px-2 py-4">
      <h1 className="text-3xl font-bold text-center mb-8">
        Fuel your Academic Journey with
      </h1>
      <h2 className="text-2xl font-semibold text-center mb-8">
        NAE's Top Faculties
      </h2>
      <div className="flex overflow-x-auto space-x-6 lg:space-x-8 no-scrollbar py-3">
        {faculties.map((faculty) => (
          <FacultyCard key={faculty.name} {...faculty} />
        ))}
      </div>
    </div>
  );
};

export default Faculties;
