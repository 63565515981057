import React from 'react';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Courses from './pages/Courses';
import Progress from './pages/Progress';
import More from './pages/More';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Navigation from './components/Navigation'; // Ensure the path is correct
import CourseContent from './pages/CourseContent';
import Profile from './pages/Profile';
import QuizPage from './pages/ObjTest';
import YouTubeVideo from './pages/YouTubeVideo';
import PdfReader from './pages/PdfReader';
import CreateCompleteTest from './pages/CreateCompleteTest';
import EditTest from './pages/EditTest';
import TestList from './pages/TestList';
import EditCoursePage from './pages/EditCoursePage';
import CourseDetails from './pages/CourseDetails';
import CollectiveCoursesPage from './pages/CollectiveCoursesPage';




const App: React.FC = () => {
  return (
    <>
      <>
        <div className='w-full h-full flex flex-col lg:flex-row-reverse bg-white p-1'>
          
          <div className='flex-1 overflow-y-auto pb-16 bg-white pt-24'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/progress" element={<Progress />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/more" element={<More />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/courseContent" element={<CourseContent />} />
              <Route path="/QuizPage" element={<QuizPage />} />
              <Route path="/VideoContent:videoId" element={<YouTubeVideo />} />
              <Route path="/pdf-reader/:fileUrl" element={<PdfReader />} />  
              <Route path="/collective-course/:id" element={<CollectiveCoursesPage />} />
              
              {/* Create Complete Test */}
              <Route path="/create-complete-test" element={<CreateCompleteTest />} />  
              <Route path="/test-list" element={<TestList />} />
              <Route path="/edit-test/:testId" element={<EditTest />} />

              <Route path="/courses/edit/:id" element={<EditCoursePage />} /> {/* Add this route */}

              <Route path="/CourseDetails/:courseId" element={<CourseDetails />} />

            </Routes>
          </div>
          <Navigation />
        </div>
      </>
    </>
  );
}


export default App;
