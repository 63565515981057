
import React, { useState, useEffect, useRef } from 'react';
import YouTubeVideo from './YouTubeVideo';
import PdfReader from './PdfReader';
import samplePdf from './samplePdf.pdf'; // Now TypeScript understands this import
import QuizPage from './ObjTest';
import { Link } from 'react-router-dom';


interface Course {
  title: string;
  price: number;
  originalPrice: number;
  discount: number;
  liveClasses: number;
  image: string;
  description: string;
}

interface Link {
  url: string;
  postingDate: string;
}

interface VideoLink extends Link {}

interface ClassNoteLink extends Link {}

interface NotesLink extends Link {}

interface DppLink extends Link {}

interface TestLink extends Link {}

interface VideoLinks {
  title: string;
  link: Link;
}

interface Doubt {
  doubtTitle: string;
  videoLinks: VideoLinks[];
}

interface MiniTest {
  testTitle: string;
  testLink: TestLink;
}

interface Lecture {
  lectureTitle: string;
  videoLink: VideoLink;
  classNoteLink: ClassNoteLink;
  notesLink: NotesLink;
  doubts: Doubt[];
  dppLink: DppLink;
  miniTest: MiniTest;
}

interface Chapter {
  chapterTitle: string;
  lectures: Lecture[];
  finalTest: Test;
}

interface Test {
  testTitle: string;
  testLink: TestLink;
}

interface CourseData {
  courseTitle: string;
  chapters: Chapter[];
  tests: Test[];
}

interface CourseProps {
  courseData: CourseData;
}


interface ChapterProps {
  chapter: Chapter;
}

interface LectureProps {
  lecture: Lecture;
}

interface DoubtProps {
  doubt: Doubt;
}

interface LinkProps {
  label: string;
  link: Link;
}

interface MiniTestProps {
  miniTest: MiniTest;
}


interface TestProps {
  test: Test;
}


const courses: Course[] = [
  {
    title: 'Yakeen NEET 3.0 2025',
    price: 4200,
    originalPrice: 4900,
    discount: 14,
    liveClasses: 250,
    image: 'https://i.ibb.co/wR7Y66t/yakeen-neet.jpg',
    description: 'FOR NEET 2025 ASPIRANTS',
  },
  {
    title: 'Arjuna NEET 3.0 2025',
    price: 4200,
    originalPrice: 4900,
    discount: 14,
    liveClasses: 0,
    image: 'https://i.ibb.co/H7qS59W/arjuna-neet.jpg',
    description: 'FOR CLASS 11TH NEET ASPIRANTS',
  },
  {
    title: 'Lakshya NEET 3.0 2025',
    price: 3900,
    originalPrice: 4500,
    discount: 13,
    liveClasses: 0,
    image: 'https://i.ibb.co/wR7Y66t/yakeen-neet.jpg',
    description: 'For Class 12TH + NEET',
  },
];

const YouTubeIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="100%" 
    fill="currentColor" 
    className="bi bi-youtube" 
    viewBox="0 0 16 16"
  >
    <path 
      d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"
    />
  </svg>
);

const BookHalfIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="40" 
    height="40" 
    fill="currentColor" 
    className="bi bi-book-half" 
    viewBox="0 0 16 16"
  >
    <path 
      d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"
    />
  </svg>
);

const JournalsIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="40" 
    height="40" 
    fill="currentColor" 
    className="bi bi-journals" 
    viewBox="0 0 16 16"
  >
    <path 
      d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2"
    />
    <path 
      d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0"
    />
  </svg>
);

const LayoutWtfIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="40" 
    height="40" 
    fill="currentColor" 
    className="bi bi-layout-wtf" 
    viewBox="0 0 16 16"
  >
    <path d="M5 1v8H1V1zM1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm13 2v5H9V2zM9 1a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM5 13v2H3v-2zm-2-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1zm12-1v2H9v-2zm-6-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1z"/>
  </svg>
);

const ClipboardCheckIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="40" 
    height="40" 
    fill="currentColor" 
    className="bi bi-clipboard2-check" 
    viewBox="0 0 16 16"
  >
    <path 
      d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"
    />
    <path 
      d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"
    />
    <path 
      d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"
    />
  </svg>
);



const ChatLeftDotsIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="100%" 

    fill="currentColor" 
    className="bi bi-chat-left-dots" 
    viewBox="0 0 16 16"
  >
    <path 
      d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
    />
    <path 
      d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    />
  </svg>
);


const Test: React.FC<TestProps> = ({ test }) => {
  return (
    <div className='bg-red-400 p-2'>
      <h3>{test.testTitle}</h3>
      <LinkComponent label="Test Link" link={test.testLink} />
    </div>
  );
};

const MiniTestComponent: React.FC<MiniTestProps> = ({ miniTest }) => {
  return (
    <div className='bg-green-300 p-2'>
      <h4>{miniTest.testTitle}</h4>
      <LinkComponent label="Mini Test Link" link={miniTest.testLink} />
    </div>
  );
};

const LinkComponent: React.FC<LinkProps> = ({ label, link }) => {
  return (
    <div className='bg-pink-200 p-2'>
      <a href={link.url} target="_blank" rel="noopener noreferrer">
        {label}
      </a>{' '}
      (Posted on: {link.postingDate})
    </div>
  );
};

const Doubt: React.FC<DoubtProps> = ({ doubt }) => {
  return (
    <div className='bg-red-100 p-2'>
      <h5>{doubt.doubtTitle}</h5>
      {doubt.videoLinks.map((videoLink, videoLinkIndex) => (
        <LinkComponent
          key={videoLinkIndex}
          label={videoLink.title}
          link={videoLink.link}
        />
      ))}
    </div>
  );
};

const Lecture: React.FC<LectureProps> = ({ lecture }) => {
  return (
    <div className='bg-green-200 p-2 m-1'>
      <h3>{lecture.lectureTitle}</h3>
      <LinkComponent label="Video" link={lecture.videoLink} />
      <LinkComponent label="Class Note" link={lecture.classNoteLink} />
      <LinkComponent label="Notes" link={lecture.notesLink} />
      <h4>Doubts</h4>
      {lecture.doubts.map((doubt, doubtIndex) => (
        <Doubt key={doubtIndex} doubt={doubt} />
      ))}
      <LinkComponent label="DPP" link={lecture.dppLink} />
      <MiniTestComponent miniTest={lecture.miniTest} />
    </div>
  );
};

const Chapter: React.FC<ChapterProps> = ({ chapter }) => {
  return (
    <div>
      <h2 className='mt-2 text-xl font-bold'>{chapter.chapterTitle}</h2>
      <h3>{getLectureCount(chapter)} Lectures</h3>
      {chapter.lectures.map((lecture, lectureIndex) => (
        <Lecture key={lectureIndex} lecture={lecture} />
      ))}
      <LinkComponent label="Final Test" link={chapter.finalTest.testLink} />
    </div>
  );
};

const getLectureCount = (chapter: Chapter): number => {
  return chapter.lectures.length;
};

const courseData: CourseData = {
  courseTitle: 'Physics NEET Preparation',
  chapters: [
    {
      chapterTitle: 'Chapter 1: Kinematics',
      lectures: [
        {
          lectureTitle: 'Lecture 1: Introduction to Kinematics',
          videoLink: {
            url: 'https://youtube.com/link-to-lecture1',
            postingDate: '2023-08-01',
          },
          classNoteLink: {
            url: 'https://naecoaching.com/notes/lecture1',
            postingDate: '2023-08-01',
          },
          notesLink: {
            url: 'https://naecoaching.com/notes/lecture1-notes',
            postingDate: '2023-08-01',
          },
          doubts: [
            {
              doubtTitle: 'Doubt 1: Velocity vs Speed',
              videoLinks: [
                {
                  title: 'Velocity Explanation',
                  link: {
                    url: 'https://youtube.com/velocity-explanation',
                    postingDate: '2023-08-02',
                  },
                },
              ],
            },
          ],
          dppLink: {
            url: 'https://naecoaching.com/dpp/lecture1',
            postingDate: '2023-08-01',
          },
          miniTest: {
            testTitle: 'Mini Test on Kinematics',
            testLink: {
              url: 'https://naecoaching.com/tests/mini-test-kinematics',
              postingDate: '2023-08-02',
            },
          },
        },
      ],
      finalTest: {
        testTitle: 'Final Test on Kinematics',
        testLink: {
          url: 'https://naecoaching.com/tests/final-test-kinematics',
          postingDate: '2023-08-15',
        },
      },
    },
    {
      chapterTitle: 'Chapter 1: Kinematics',
      lectures: [
        {
          lectureTitle: 'Lecture 1: Introduction to Kinematics',
          videoLink: {
            url: 'https://youtube.com/link-to-lecture1',
            postingDate: '2023-08-01',
          },
          classNoteLink: {
            url: 'https://naecoaching.com/notes/lecture1',
            postingDate: '2023-08-01',
          },
          notesLink: {
            url: 'https://naecoaching.com/notes/lecture1-notes',
            postingDate: '2023-08-01',
          },
          doubts: [
            {
              doubtTitle: 'Doubt 1: Velocity vs Speed',
              videoLinks: [
                {
                  title: 'Velocity Explanation',
                  link: {
                    url: 'https://youtube.com/velocity-explanation',
                    postingDate: '2023-08-02',
                  },
                },
              ],
            },
          ],
          dppLink: {
            url: 'https://naecoaching.com/dpp/lecture1',
            postingDate: '2023-08-01',
          },
          miniTest: {
            testTitle: 'Mini Test on Kinematics',
            testLink: {
              url: 'https://naecoaching.com/tests/mini-test-kinematics',
              postingDate: '2023-08-02',
            },
          },
        },
        {
          lectureTitle: 'Lecture 1: Introduction to Kinematics',
          videoLink: {
            url: 'https://youtube.com/link-to-lecture1',
            postingDate: '2023-08-01',
          },
          classNoteLink: {
            url: 'https://naecoaching.com/notes/lecture1',
            postingDate: '2023-08-01',
          },
          notesLink: {
            url: 'https://naecoaching.com/notes/lecture1-notes',
            postingDate: '2023-08-01',
          },
          doubts: [
            {
              doubtTitle: 'Doubt 1: Velocity vs Speed',
              videoLinks: [
                {
                  title: 'Velocity Explanation',
                  link: {
                    url: 'https://youtube.com/velocity-explanation',
                    postingDate: '2023-08-02',
                  },
                },
              ],
            },
          ],
          dppLink: {
            url: 'https://naecoaching.com/dpp/lecture1',
            postingDate: '2023-08-01',
          },
          miniTest: {
            testTitle: 'Mini Test on Kinematics',
            testLink: {
              url: 'https://naecoaching.com/tests/mini-test-kinematics',
              postingDate: '2023-08-02',
            },
          },
        },
        {
          lectureTitle: 'Lecture 1: Introduction to Kinematics',
          videoLink: {
            url: 'https://youtube.com/link-to-lecture1',
            postingDate: '2023-08-01',
          },
          classNoteLink: {
            url: 'https://naecoaching.com/notes/lecture1',
            postingDate: '2023-08-01',
          },
          notesLink: {
            url: 'https://naecoaching.com/notes/lecture1-notes',
            postingDate: '2023-08-01',
          },
          doubts: [
            {
              doubtTitle: 'Doubt 1: Velocity vs Speed',
              videoLinks: [
                {
                  title: 'Velocity Explanation',
                  link: {
                    url: 'https://youtube.com/velocity-explanation',
                    postingDate: '2023-08-02',
                  },
                },
              ],
            },
          ],
          dppLink: {
            url: 'https://naecoaching.com/dpp/lecture1',
            postingDate: '2023-08-01',
          },
          miniTest: {
            testTitle: 'Mini Test on Kinematics',
            testLink: {
              url: 'https://naecoaching.com/tests/mini-test-kinematics',
              postingDate: '2023-08-02',
            },
          },
        },
        {
          lectureTitle: 'Lecture 1: Introduction to Kinematics',
          videoLink: {
            url: 'https://youtube.com/link-to-lecture1',
            postingDate: '2023-08-01',
          },
          classNoteLink: {
            url: 'https://naecoaching.com/notes/lecture1',
            postingDate: '2023-08-01',
          },
          notesLink: {
            url: 'https://naecoaching.com/notes/lecture1-notes',
            postingDate: '2023-08-01',
          },
          doubts: [
            {
              doubtTitle: 'Doubt 1: Velocity vs Speed',
              videoLinks: [
                {
                  title: 'Velocity Explanation',
                  link: {
                    url: 'https://youtube.com/velocity-explanation',
                    postingDate: '2023-08-02',
                  },
                },
              ],
            },
          ],
          dppLink: {
            url: 'https://naecoaching.com/dpp/lecture1',
            postingDate: '2023-08-01',
          },
          miniTest: {
            testTitle: 'Mini Test on Kinematics',
            testLink: {
              url: 'https://naecoaching.com/tests/mini-test-kinematics',
              postingDate: '2023-08-02',
            },
          },
        },
      ],
      finalTest: {
        testTitle: 'Final Test on Kinematics',
        testLink: {
          url: 'https://naecoaching.com/tests/final-test-kinematics',
          postingDate: '2023-08-15',
        },
      },
    },
  ],
  tests: [
    {
      testTitle: "Test 1: Mechanics and Electromagnetism",
      testLink: {
        url: "https://example.com/test1",
        postingDate: "2024-07-20"
      }
    }
  ],
};

// Sample JSON data
const sampleData = {
  courseTitle: "Advanced Physics for Class 12",
  chapters: [
    {
      chapterTitle: "Chapter 1: Mechanics",
      lectures: [
        {
          lectureTitle: "Lecture 1: Newton's Laws of Motion",
          videoLink: {
            url: "https://example.com/lecture1-video",
            postingDate: "2024-07-01"
          },
          classNoteLink: {
            url: "https://example.com/lecture1-classnote",
            postingDate: "2024-07-02"
          },
          notesLink: {
            url: "https://example.com/lecture1-notes",
            postingDate: "2024-07-03"
          },
          doubts: [
            {
              doubtTitle: "Understanding Newton's First Law",
              videoLinks: [
                {
                  title: "Newton's First Law Explained",
                  link: {
                    url: "https://example.com/doubt1-video1",
                    postingDate: "2024-07-04"
                  }
                },
                {
                  title: "Common Misconceptions",
                  link: {
                    url: "https://example.com/doubt1-video2",
                    postingDate: "2024-07-05"
                  }
                }
              ]
            },
            {
              doubtTitle: "Applications of Newton's Second Law",
              videoLinks: [
                {
                  title: "Real-life Examples",
                  link: {
                    url: "https://example.com/doubt2-video1",
                    postingDate: "2024-07-06"
                  }
                }
              ]
            }
          ],
          dppLink: {
            url: "https://example.com/lecture1-dpp",
            postingDate: "2024-07-07"
          },
          miniTest: {
            testTitle: "Mini Test: Newton's Laws",
            testLink: {
              url: "https://example.com/miniTest1",
              postingDate: "2024-07-08"
            }
          }
        },
        {
          lectureTitle: "Lecture 2: Newton's Laws of Motion",
          videoLink: {
            url: "https://example.com/lecture1-video",
            postingDate: "2024-07-01"
          },
          classNoteLink: {
            url: "https://example.com/lecture1-classnote",
            postingDate: "2024-07-02"
          },
          notesLink: {
            url: "https://example.com/lecture1-notes",
            postingDate: "2024-07-03"
          },
          doubts: [
            {
              doubtTitle: "Understanding Newton's First Law",
              videoLinks: [
                {
                  title: "Newton's First Law Explained",
                  link: {
                    url: "https://example.com/doubt1-video1",
                    postingDate: "2024-07-04"
                  }
                },
                {
                  title: "Common Misconceptions",
                  link: {
                    url: "https://example.com/doubt1-video2",
                    postingDate: "2024-07-05"
                  }
                }
              ]
            },
            {
              doubtTitle: "Applications of Newton's Second Law",
              videoLinks: [
                {
                  title: "Real-life Examples",
                  link: {
                    url: "https://example.com/doubt2-video1",
                    postingDate: "2024-07-06"
                  }
                }
              ]
            }
          ],
          dppLink: {
            url: "https://example.com/lecture1-dpp",
            postingDate: "2024-07-07"
          },
          miniTest: {
            testTitle: "Mini Test: Newton's Laws",
            testLink: {
              url: "https://example.com/miniTest1",
              postingDate: "2024-07-08"
            }
          }
        }
      ]
    },
    {
      chapterTitle: "Chapter 1: Mechanics",
      lectures: [
        {
          lectureTitle: "Lecture 1: Newton's Laws of Motion",
          videoLink: {
            url: "https://example.com/lecture1-video",
            postingDate: "2024-07-01"
          },
          classNoteLink: {
            url: "https://example.com/lecture1-classnote",
            postingDate: "2024-07-02"
          },
          notesLink: {
            url: "https://example.com/lecture1-notes",
            postingDate: "2024-07-03"
          },
          doubts: [
            {
              doubtTitle: "Understanding Newton's First Law",
              videoLinks: [
                {
                  title: "Newton's First Law Explained",
                  link: {
                    url: "https://example.com/doubt1-video1",
                    postingDate: "2024-07-04"
                  }
                },
                {
                  title: "Common Misconceptions",
                  link: {
                    url: "https://example.com/doubt1-video2",
                    postingDate: "2024-07-05"
                  }
                }
              ]
            },
            {
              doubtTitle: "Applications of Newton's Second Law",
              videoLinks: [
                {
                  title: "Real-life Examples",
                  link: {
                    url: "https://example.com/doubt2-video1",
                    postingDate: "2024-07-06"
                  }
                }
              ]
            }
          ],
          dppLink: {
            url: "https://example.com/lecture1-dpp",
            postingDate: "2024-07-07"
          },
          miniTest: {
            testTitle: "Mini Test: Newton's Laws",
            testLink: {
              url: "https://example.com/miniTest1",
              postingDate: "2024-07-08"
            }
          }
        }
      ]
    },
    {
      chapterTitle: "Chapter 1: Mechanics",
      lectures: [
        {
          lectureTitle: "Lecture 1: Newton's Laws of Motion",
          videoLink: {
            url: "https://example.com/lecture1-video",
            postingDate: "2024-07-01"
          },
          classNoteLink: {
            url: "https://example.com/lecture1-classnote",
            postingDate: "2024-07-02"
          },
          notesLink: {
            url: "https://example.com/lecture1-notes",
            postingDate: "2024-07-03"
          },
          doubts: [
            {
              doubtTitle: "Understanding Newton's First Law",
              videoLinks: [
                {
                  title: "Newton's First Law Explained",
                  link: {
                    url: "https://example.com/doubt1-video1",
                    postingDate: "2024-07-04"
                  }
                },
                {
                  title: "Common Misconceptions",
                  link: {
                    url: "https://example.com/doubt1-video2",
                    postingDate: "2024-07-05"
                  }
                }
              ]
            },
            {
              doubtTitle: "Applications of Newton's Second Law",
              videoLinks: [
                {
                  title: "Real-life Examples",
                  link: {
                    url: "https://example.com/doubt2-video1",
                    postingDate: "2024-07-06"
                  }
                }
              ]
            }
          ],
          dppLink: {
            url: "https://example.com/lecture1-dpp",
            postingDate: "2024-07-07"
          },
          miniTest: {
            testTitle: "Mini Test: Newton's Laws",
            testLink: {
              url: "https://example.com/miniTest1",
              postingDate: "2024-07-08"
            }
          }
        }
      ]
    }
  ],
  tests: [
    {
      testTitle: "Test 1: Mechanics and Electromagnetism",
      testLink: {
        url: "https://example.com/test1",
        postingDate: "2024-07-20"
      }
    }
  ]
};

const Course: React.FC<CourseProps> = ({ courseData }) => {
  return (
    <div className='bg-gray-200 p-2'>
      <h1 className='mt-4 text-xl font-bold'>{courseData.courseTitle}</h1>
      {courseData.chapters.map((chapter, chapterIndex) => (
        <Chapter key={chapterIndex} chapter={chapter} />
      ))}
      <h2 className='mt-4 text-xl font-bold'>Tests</h2>
      {courseData.tests.map((test, testIndex) => (
        <Test key={testIndex} test={test} />
      ))}
    </div>
  );
};

const ContentDisplay = () => {
  return (
    <div className="bg-white rounded-xl shadow-md p-2 m-1">
      <div className="flex flex-col md:flex-row">
       <div className=''>
       <div className="relative w-full">
        <img
          src='https://i.ibb.co/wR7Y66t/yakeen-neet.jpg'
          alt='image'
          className="rounded-xl w-full md:w-60 h-48 md:h-40 object-cover"
        />
        <div className="absolute bottom-4 left-4 bg-white rounded-xl px-2 py-1 text-sm font-semibold">
        Lecture 3
        </div>
       </div>
       </div>
       <div>
        <div className='p-2 lg:pl-8'>
          <p className="text-lg font-bold">Motion in straight line</p>
        </div>
        <div className='w-full px-4 lg:px-8 flex gap-2 md:gap-4 justify-between'>
          <div className=' flex flex-col justify-start items-center text-2xl text-red-600 w-10 md:w-12'>
              <YouTubeIcon />
              <p className='text-sm font-semibold text-black'>Lecture</p>
          </div>
          <div className=' flex flex-col justify-start items-center text-2xl text-blue-600 w-8 md:w-10'>
              <JournalsIcon />
              <p className='text-sm font-semibold text-black'>class notes</p>
          </div>
          <div className=' flex flex-col justify-start items-center text-2xl text-black w-10 md:w-12'>
              <BookHalfIcon />
              <p className='text-sm font-semibold text-black'>Notes</p>
          </div>
          <div className=' flex flex-col justify-start items-center text-2xl text-black w-8 md:w-10'>
              <ChatLeftDotsIcon />
              <p className='text-sm font-semibold text-black'>Doubts</p>
          </div>
          <div className=' flex flex-col justify-start items-center text-2xl text-purple-700 w-10 md:w-12'>
              <ClipboardCheckIcon />
              <p className='text-sm font-semibold text-black'>DPP</p>
          </div>
          <div className=' flex flex-col justify-start items-center text-2xl text-green-900 w-10 md:w-12'>
              <LayoutWtfIcon />
              <p className='text-sm font-semibold text-black'>More</p>
          </div>
          
        </div>
       </div>
      </div>
        
    </div>
  );
};

// ExpandableSection component

const ExpandableSection: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (sectionRef.current && !sectionRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div ref={sectionRef} style={{ position: 'relative', cursor: 'pointer' }} className='my-1 rounded-xl bg-emerald-100 shadow-emerald-950 shadow-sm'>
      <div className='rounded-xl shadow-emerald-100 shadow-md p-3 bg-emerald-50' onClick={toggleSection} >
        <div className="flex">
       <div className='w-1/3 md:w-40'>
       <div className=" w-full ">
        <img
          src='https://i.ibb.co/wR7Y66t/yakeen-neet.jpg'
          alt='image'
          className="rounded-xl w-30 md:w-60 h-24 md:h-40 object-cover"
        />
       </div>
       </div>
       <div className='w-2/3'>
        <div className='p-2 lg:pl-8'>
          <p className="text-lg text-wrap font-bold">Motion in straight line</p>
        </div>
        <div className='w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between'>
        <div className=" bg-white rounded-xl shadow-xl px-2 py-1 text-sm font-semibold">
        7 Lectures
        </div>
        </div>
        <div className='relative bottom-0 mt-4 w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between'>

        <div className=" bg-green-500 rounded-xl px-2 py-1 text-sm font-semibold w-auto">
        click to {isOpen ? 'Close' : 'Expand'}
        </div>
        </div>
       </div>
      </div>
      </div>
      {isOpen && (
        <div className='rounded-xl shadow-sm bg-emerald-100'>
          <ContentDisplay />
          <ContentDisplay />
          <ContentDisplay />
          <ContentDisplay />
          <ContentDisplay />
        </div>
      )}
    </div>
  );
};

const videoUrl = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'; // Replace with your YouTube video URL

const CourseContent = () => {
  return (

    <div className="container mx-auto p-4 flex justify-center itmes-center">
      <div className="w-full md:max-w-2xl">
      <h1 className="text-3xl font-bold text-center mb-8">
        Explore Our Courses
      </h1>

      <QuizPage />
      <YouTubeVideo url={videoUrl} />

      <div className="flex justify-center items-center min-h-screen bg-gray-200">
            <div className="w-full max-w-3xl">
                <h1 className="text-center text-2xl font-semibold mb-6 text-gray-800">PDF Reader Example</h1>
                <Link to={`/pdf-reader/${samplePdf}`}>
                  <button className="px-4 py-2 bg-blue-500 text-white rounded">Open PDF</button>
              </Link>
            </div>
        </div>

      <div className="flex flex-col gap-2">
        {courses.map((course) => (
          <ExpandableSection key={course.title} />

        ))}
      </div>
      <Course courseData={courseData} />
      </div>
    </div>
  );
};

export default CourseContent;

