// src/components/Tests.tsx
import React from 'react';

interface Test {
  name: string;
  date: string; // Format: YYYY-MM-DD
  score: string; // Example: "85/100"
}

interface TestsProps {
  tests: Test[];
}

const Tests: React.FC<TestsProps> = ({ tests }) => {
  // Sort tests by date, most recent first
  const sortedTests = [...tests].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return (
    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md mb-6">
      <h2 className="text-xl font-semibold mb-4">Test Results</h2>
      {sortedTests.length > 0 ? (
        <ul className="list-none space-y-4">
          {sortedTests.map((test, index) => (
            <li key={index} className="flex justify-between items-center">
              <div>
                <h3 className="text-lg font-semibold">{test.name}</h3>
                <p className="text-gray-500">{test.date}</p>
              </div>
              <p className="text-gray-800 font-bold">{test.score}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No test results available.</p>
      )}
    </div>
  );
};

export default Tests;
