import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Token ${token}`,
  },
});

interface LectureFormProps {
  chapterId: number;
  lectureId?: number;
}

const LectureForm: React.FC<LectureFormProps> = ({ chapterId, lectureId }) => {
  const [topic, setTopic] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isUnlocked, setIsUnlocked] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [ytLinkRef, setYtLinkRef] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [addButtonOpen, setAddButtonOpen] = useState<Boolean>(false);

  // For existing files and new file uploads
  const [existingCnotesRef, setExistingCnotesRef] = useState<string | null>(null);
  const [existingNotesRef, setExistingNotesRef] = useState<string | null>(null);
  const [existingDppRef, setExistingDppRef] = useState<string | null>(null);
  const [cnotesFile, setCnotesFile] = useState<File | null>(null);
  const [notesFile, setNotesFile] = useState<File | null>(null);
  const [dppFile, setDppFile] = useState<File | null>(null);

  useEffect(() => {
    if (lectureId) {
      // Fetch existing lecture data for updating
      const fetchLectureData = async () => {
        try {
          const response = await axiosInstance.get(`/courses/lectures/${lectureId}/`);
          const data = response.data;
          setTopic(data.topic);
          setDescription(data.description);
          setIsUnlocked(data.is_unlocked);
          setIsCompleted(data.is_completed);
          setYtLinkRef(data.yt_link_ref || null);
          setExistingCnotesRef(data.cnotes_ref || null);
          setExistingNotesRef(data.notes_ref || null);
          setExistingDppRef(data.dpp_ref || null);
        } catch (err) {
          setError('Error fetching lecture data.');
        }
      };
      fetchLectureData();
    }
  }, [lectureId]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {

    if (e.target.files && e.target.files.length > 0) {
      
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const lectureData = {
      "topic": topic,
      "description": description,
      "is_unlocked": isUnlocked,
      "is_completed": isCompleted,
      "yt_link_ref": ytLinkRef,
      "chapter": chapterId,
      "created_by": 1,
    };
    const l = {"topic": "Test Topic updated", "description": "Test Description", "is_unlocked": true, "is_completed": false, "yt_link_ref": "https://www.youtube.com/watch?v=lsRXI4WtnpE", "chapter": 1, "created_by": 1}

    try {
      let response;
      if (lectureId) {
        // Update lecture details first
        response = await axiosInstance.put(`/courses/lectures/${lectureId}/`, lectureData);
        console.log(response)
        setError(null)
        setMessage('Lecture updated successfully!');
      } else {
        // Create a new lecture
        response = await axiosInstance.post(`/courses/lectures-create/`, lectureData
        );
        console.log(response)
        setError(null)
        setMessage('Lecture created successfully!');
      }

      
      const lectureIdCreatedOrUpdated = response.data.id || lectureId;
      console.log(lectureIdCreatedOrUpdated);
      

      // Handle file uploads separately
      const formData = new FormData();
      // formData.append('topic', topic);
      // formData.append('description', response.data.description);
      // formData.append('is_unlocked', response.data.is_unlocked);
      // formData.append('is_completed', response.data.is_completed);
      // formData.append('yt_link_ref', response.data.yt_link_ref);
      // formData.append('chapter', response.data.chapter);
      // formData.append('created_by', response.data.created_by)
      if (cnotesFile) formData.append('cnotes_ref', cnotesFile);
      if (notesFile) formData.append('notes_ref', notesFile);
      if (dppFile) formData.append('dpp_ref', dppFile);

      if (cnotesFile || notesFile || dppFile) {
        await axiosInstance.put(`/courses/lectures-update/${lectureIdCreatedOrUpdated}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      window.location.reload();

      setError(null);
    } catch {
      setError('Error creating/updating lecture.');
      setMessage('');
    }
  };

  const toggleSection = () => {
    setAddButtonOpen(!addButtonOpen);
  };

  // const handleSubmit = async (e: React.FormEvent) => {
  //   e.preventDefault();
  
  //   const formData = new FormData();
  //   formData.append('topic', topic);
  //   formData.append('description', description);
  //   formData.append('is_unlocked', isUnlocked.toString());
  //   formData.append('is_completed', isCompleted.toString());
  //   formData.append('yt_link_ref', ytLinkRef ?? '');
  //   formData.append('chapter', chapterId.toString());
  
  //   if (cnotesFile) formData.append('cnotes_ref', cnotesFile);
  //   if (notesFile) formData.append('notes_ref', notesFile);
  //   if (dppFile) formData.append('dpp_ref', dppFile);
  
  //   console.log(formData);
    
  //   try {
  //     let response;
  //     if (lectureId) {
  //       // Update lecture details
  //       response = await axiosInstance.put(`/courses/lectures/${lectureId}/`, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       });
  //       setMessage('Lecture updated successfully!');
  //     } else {
  //       // Create a new lecture
  //       response = await axiosInstance.post(`/courses/lectures-create/`, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       });
  //       setMessage('Lecture created successfully!');
  //     }
  
  //     setError(null);
  //   } catch (err) {
  //     setError('Error creating/updating lecture.');
  //     setMessage(null);
  //   }
  // };
  

  return (
    <div className="container mx-auto px-4 pb-4 flex justify-center items-center">
      <div className="w-full md:max-w-2xl">
        <div
          style={{ position: 'relative', cursor: 'pointer' }}
          className="rounded-xl bg-blue-100 shadow-blue-500 shadow-sm"
        >
          <div
            className="rounded-xl shadow-blue-100 shadow-md p-2 bg-blue-50"
            onClick={toggleSection}
          >
            <div className="relative bottom-0 mt-2 w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between">
              <div className={`rounded-xl px-4 text-lg text-white w-auto ${lectureId ? 'bg-green-500' : 'bg-purple-500'}`}>
                {lectureId ? (
                  <>{addButtonOpen ? 'Close' : 'Update the Lecture'}</>
                ) : (
                  <>{addButtonOpen ? 'Close' : 'Add a New Lecture'}</>
                )}
              </div>
            </div>
          </div>
          {addButtonOpen && (
            <div className="rounded-xl shadow-sm bg-blue-100 p-1">
              <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
                <h1 className="text-2xl font-bold mb-4">
                  {lectureId ? 'Update Lecture' : 'Create New Lecture'}
                </h1>

                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Topic
                    </label>
                    <input
                      type="text"
                      value={topic}
                      onChange={(e) => setTopic(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      YouTube Link (Optional)
                    </label>
                    <input
                      type="url"
                      value={ytLinkRef || ''}
                      onChange={(e) => setYtLinkRef(e.target.value || null)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>

                  {/* File Upload Inputs */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Course Notes (PDF)
                    </label>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(e, setCnotesFile)}
                      className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100"
                    />
                    {existingCnotesRef && <p className="text-sm text-gray-600">Existing file: {existingCnotesRef}</p>}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Lecture Notes (PDF)
                    </label>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(e, setNotesFile)}
                      className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100"
                    />
                    {existingNotesRef && <p className="text-sm text-gray-600">Existing file: {existingNotesRef}</p>}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      DPP Reference (PDF)
                    </label>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(e, setDppFile)}
                      className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100"
                    />
                    {existingDppRef && <p className="text-sm text-gray-600">Existing file: {existingDppRef}</p>}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Is Unlocked?
                    </label>
                    <input
                      type="checkbox"
                      checked={isUnlocked}
                      onChange={(e) => setIsUnlocked(e.target.checked)}
                      className="mt-1"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Is Completed?
                    </label>
                    <input
                      type="checkbox"
                      checked={isCompleted}
                      onChange={(e) => setIsCompleted(e.target.checked)}
                      className="mt-1"
                    />
                  </div>

                  <div>
                    {message && <p className="text-green-600">{message}</p>}
                    {error && <p className="text-red-600">{error}</p>}
                    <button
                      type="submit"
                      className="mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600"
                    >
                      {lectureId ? 'Update Lecture' : 'Create Lecture'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LectureForm;
