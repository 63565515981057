import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CourseCard from '../components/CourseCard';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CourseForm from '../components/CreateUpdateCourse';

interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string;
  class_for: { name: string }[];
  teacher: { user: { first_name: string; last_name: string } };
  mode: string;
  tags: { name: string }[];
  price: string;
  discount_percentage: string;
}

const API_URL = process.env.REACT_APP_API_URL;

// Helper function to group courses by class
const groupCoursesByClass = (courses: Course[]) => {
  return courses.reduce((grouped: Record<string, Course[]>, course) => {
    const className = course.class_for[0]?.name || 'Unknown'; // Get class name or 'Unknown'
    if (!grouped[className]) {
      grouped[className] = [];
    }
    grouped[className].push(course);
    return grouped;
  }, {});
};

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const navigate = useNavigate(); // Use useNavigate for navigation
  // Fetch courses from API
  useEffect(() => {
    axios.get(`${API_URL}/courses/courses`)
      .then(response => {
        console.log(response.data);
        
        setCourses(response.data);
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  }, []);

  const handleAddCourse = () => {
    // Logic for adding a new course
    console.log('Add new course');
  };

  
  const handleEditCourse = (courseId: number) => {
    navigate(`/coursedetails/${courseId}`); // Navigate to the edit course page
  };

  // Group courses by class
  const groupedCourses = groupCoursesByClass(courses);

  return (
    <div className="container mx-auto p-4">
      {/* Button to add a new course */}
      <div>
        <CourseForm />
      </div>

      {/* Loop through each class and render its courses */}
      {Object.keys(groupedCourses).map((className) => (
        <div key={className}>
          <h2 className="text-2xl font-bold text-gray-900 my-6">{className}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {groupedCourses[className].map((course) => (
               <CourseCard key={course.id} course={course} onEdit={handleEditCourse} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Courses;
