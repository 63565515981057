import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Signup: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    subjects: '',
    qualifications: '',
    experience: '',
    role: 'teacher' , // Default role
    student_class: '',
  });
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const { signup, login } = useAuth();
  const navigate = useNavigate();

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    // Ensure phone number is exactly 10 digits and does not start with 1, 2, 3, 4, or 5
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleNext = async () => {
    if (step === 1 && formData.firstName && formData.lastName) {
      setStep(2);
    } else if (step === 2 && formData.email) {
      // Basic email format validation
      if (!isValidEmail(formData.email)) {
        setError('Invalid email format.');
        return;
      }
      setError(''); // Clear any previous error
      setStep(3);
    } else if (step === 3 && formData.phoneNumber) {
      // Validate phone number
      if (!isValidPhoneNumber(formData.phoneNumber)) {
        setError('Invalid phone number. It must be 10 digits and start with 6-9.');
        return;
      }
      setError(''); // Clear any previous error
      setStep(4);
    } else if (step === 4 && formData.subjects && formData.qualifications && formData.experience) {
      setStep(5);
    } else if (step === 5) {
      // Validate password
      if (formData.password.length < 6) {
        setError('Password must be at least 6 characters long.');
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
      setError(''); // Clear any previous error
      handleSubmit();
    } else {
      setError('Please fill in all fields correctly.');
    }
  };
  
  

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleSubmit = async () => {
    try {
      await signup(formData);
      await login(formData.phoneNumber, formData.password);
      navigate('/usersPage');
    } catch (err) {
      setError('Signup failed. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-6 rounded-2xl shadow-2xl w-full max-w-md">
        <h2 className="text-2xl mb-4 font-bold">Signup</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {step === 1 && (
          <>
            <input
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
            <input
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
          </>
        )}

        {step === 2 && (
          <input
            name="email"
            placeholder="Email"
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
          />
        )}

        {step === 3 && (
          <input
            name="phoneNumber"
            placeholder="Phone Number"
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
          />
        )}

        {step === 4 && (
          <>
            <input
              name="subjects"
              placeholder="Subjects"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
            <input
              name="qualifications"
              placeholder="Qualifications"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
            <input
              name="experience"
              placeholder="Teaching Experience"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
          </>
        )}

        {step === 5 && (
          <>
            <input
              name="password"
              type="password"
              placeholder="Password"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
            <input
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
          </>
        )}

        <div className="flex justify-between mt-4">
          {step > 1 && (
            <button
              type="button"
              onClick={handleBack}
              className="px-4 py-2 bg-gray-500 text-white rounded-xl hover:bg-gray-600"
            >
              Back
            </button>
          )}
          <button
            type="button"
            onClick={handleNext}
            className="px-4 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600"
          >
            {step === 5 ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
