import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Define the structure of a Test
interface Test {
  id: number;
  name: string;
  description: string; 
  time_limit: number;
  created_at: string; // Assuming this is the field you want to sort by
}

const API_BASE_URL = process.env.REACT_APP_API_URL;  // Replace with actual base URL


const TestList: React.FC = () => {
  const [tests, setTests] = useState<Test[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();



  // Get the token from localStorage
  const token = localStorage.getItem('token');


  // Create axios instance with token in headers
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        Authorization: `Token ${token}`  // Add token to Authorization header
    }
  });

  // Fetch the list of tests from the API
  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axiosInstance.get('/online-test/tests/');

        // Log the entire response data to check its structure
        // console.log('API response:', response.data);

        // Check if response.data contains a list of tests
        if (Array.isArray(response.data)) {
          // Sort the tests by created_at (recent first)
          const sortedTests = response.data.sort(
            (a: Test, b: Test) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
          setTests(sortedTests);
        } else if (Array.isArray(response.data)) {
          // If the tests are inside a "tests" property
          const sortedTests = response.data.sort(
            (a: Test, b: Test) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
          setTests(sortedTests);
        } else {
          throw new Error('Unexpected API response format');
        }
      } catch (err) {
        console.error('Error fetching tests:', err);
        setError('Failed to load tests');
      } finally {
        setLoading(false);
      }
    };

    fetchTests();
  }, []);
  // Handle test selection to edit
  const handleEdit = (testId: number) => {
    // Redirect to EditTest page with selected testId
    navigate(`/edit-test/${testId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold">Test List</h1>
      <ul className="mt-4">
        {tests.map((test) => (
          <li key={test.id} className="border-b py-4">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-lg font-semibold">{test.name}</h2>
                <p className="text-sm text-gray-500">{test.description}</p>
                <p className="text-sm text-gray-400">Created on: {new Date(test.created_at).toLocaleDateString()}</p>
              </div>
              <button
                className="bg-indigo-500 text-white px-4 py-2"
                onClick={() => handleEdit(test.id)}
              >
                Edit
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TestList;
