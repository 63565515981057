import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Token ${token}`,
  },
});

interface Class {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

interface Teacher {
  id: number;
  user: {
    first_name: string;
    last_name: string;
  };
}

interface Course {
  id: number;
  course_name: string;
  description: string;
  course_img: string;
  class_for: Class[];
  teacher: { user: Teacher };
  mode: string;
  tags: Tag[];
  price: string;
  discount_percentage: string;
}

const EditCoursePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [course, setCourse] = useState<Course | null>(null);
  const [classes, setClasses] = useState<Class[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [change, setChange] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    course_name: '',
    description: '',
    course_img: '',
    class_for: [] as string[], // Changed to string[]
    tags: [] as string[], // Changed to string[]
    mode: '',
    price: '',
    discount_percentage: '',
    teacher_id: '', // Store selected teacher's ID
  });
  const [newClass, setNewClass] = useState('');
  const [newTag, setNewTag] = useState('');
  const [image, setImage] = useState<File | null>(null);

  useEffect(() => {
    // Fetch the course data
    axiosInstance.get(`courses/courses/${id}/`)
      .then(response => {
        const data = response.data;
        setCourse(data);
        setFormData({
          course_name: data.course_name,
          description: data.description,
          course_img: data.course_img,
          class_for: data.class_for.map((c: Class) => c.id.toString()), // Convert to string[]
          tags: data.tags.map((t: Tag) => t.id.toString()), // Convert to string[]
          mode: data.mode,
          price: data.price,
          discount_percentage: data.discount_percentage,
          teacher_id:  '', // Convert teacher ID to string
        });
      })
      .catch(error => {
        console.error('Error fetching course:', error);
      });

    // Fetch classes, tags, and teachers
    axiosInstance.get('courses/classes')
      .then(response => {
        setClasses(response.data);
      })
      .catch(error => {
        console.error('Error fetching classes:', error);
      });

    axiosInstance.get('courses/tags')
      .then(response => {
        setTags(response.data);
      })
      .catch(error => {
        console.error('Error fetching tags:', error);
      });

    axiosInstance.get('users/teachers') // Fetch teachers from the API
      .then(response => {
        setTeachers(response.data); // Set fetched teachers
      })
      .catch(error => {
        console.error('Error fetching teachers:', error);
      });
  }, [id, change]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, options } = e.target;
    const selectedOptions = Array.from(options)
      .filter(option => option.selected)
      .map(option => option.value);
    setFormData(prev => ({ ...prev, [name]: selectedOptions }));
  };

  const handleAddNewClass = () => {
    if (newClass.trim()) {
      axiosInstance.post('courses/classes/', { name: newClass })
        .then(response => {
          setClasses([...classes, response.data]);
          setNewClass('');
          setChange(!change);
        })
        .catch(error => {
          console.error('Error adding class:', error);
        });
    }
  };

  const handleAddNewTag = () => {
    if (newTag.trim()) {
      axiosInstance.post('courses/tags/', { name: newTag })
        .then(response => {
          setTags([...tags, response.data]);
          setNewTag('');
          setChange(!change);
        })
        .catch(error => {
          console.error('Error adding tag:', error);
        });
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formDataToSubmit = new FormData();

    // Add all fields to FormData
    formDataToSubmit.append('course_name', formData.course_name);
    formDataToSubmit.append('description', formData.description);

    // Add course image, either new or existing
    if (image) {
      formDataToSubmit.append('course_img', image); // New image
    } else {
      formDataToSubmit.append('course_img', formData.course_img); // Keep old image
    }

    // Add class_for and tags, which are arrays
    formDataToSubmit.append('class_for', JSON.stringify(formData.class_for));
    formDataToSubmit.append('tags', JSON.stringify(formData.tags));

    // Add other fields
    formDataToSubmit.append('mode', formData.mode);
    formDataToSubmit.append('price', formData.price);
    formDataToSubmit.append('discount_percentage', formData.discount_percentage);

    // Add selected teacher's ID
    formDataToSubmit.append('teacher_id', formData.teacher_id);

    // Make the PUT request to update the course
    axiosInstance.put(`courses/courses/${id}/`, formDataToSubmit, {
      headers: {
        'Content-Type': 'multipart/form-data', // Handle form data
      }
    })
      .then(() => {
        navigate('/courses'); // Redirect to the courses page
      })
      .catch(error => {
        console.error('Error updating course:', error);
      });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Edit Course</h1>
      {course ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium">Course Name</label>
            <input
              type="text"
              name="course_name"
              value={formData.course_name}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Class For</label>
            <select
              name="class_for"
              multiple
              value={formData.class_for}
              onChange={handleSelectChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              {classes.map(cls => (
                <option key={cls.id} value={cls.id}>
                  {cls.name}
                </option>
              ))}
            </select>
            <div className="flex mt-2">
              <input
                type="text"
                value={newClass}
                onChange={(e) => setNewClass(e.target.value)}
                placeholder="New class name"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <button type="button" onClick={handleAddNewClass} className="bg-green-500 text-white px-4 py-2 rounded-lg ml-2">
                Add Class
              </button>
            </div>
          </div>

          <div>
          <label className="block text-sm font-medium">Tags</label>
          <div className="grid grid-cols-3 gap-2"> {/* Adjust the grid as needed */}
            {tags.map(tag => (
              <div key={tag.id} className="flex items-center">
                <input
                  type="checkbox"
                  id={`tag-${tag.id}`}
                  name="tags"
                  value={tag.id}
                  checked={formData.tags.includes(tag.id.toString())} // Convert ID to string if needed
                  onChange={(e) => {
                    const newTags = e.target.checked
                      ? [...formData.tags, e.target.value] // Add tag if checked
                      : formData.tags.filter(tagId => tagId !== e.target.value); // Remove tag if unchecked

                    setFormData(prev => ({
                      ...prev,
                      tags: newTags,
                    }));
                  }}
                  className="mr-2"
                />
                <label htmlFor={`tag-${tag.id}`} className="text-sm">{tag.name}</label>
              </div>
            ))}
          </div>
            <div className="flex mt-2">
              <input
                type="text"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                placeholder="New tag name"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <button type="button" onClick={handleAddNewTag} className="bg-green-500 text-white px-4 py-2 rounded-lg ml-2">
                Add Tag
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium">Mode</label>
            <input
              type="text"
              name="mode"
              value={formData.mode}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Price</label>
            <input
              type="text"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Discount Percentage</label>
            <input
              type="text"
              name="discount_percentage"
              value={formData.discount_percentage}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Teacher</label>
            <select
              name="teacher_id"
              value={formData.teacher_id}
              onChange={(e) => setFormData(prev => ({ ...prev, teacher_id: e.target.value }))}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="">Select Teacher</option>
              {teachers.map(teacher => (
                <option key={teacher.id} value={teacher.id}>
                  {teacher.user.first_name} {teacher.user.last_name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium">Course Image</label>
            <input
              type="file"
              onChange={handleImageChange}
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            Update Course
          </button>
        </form>
      ) : (
        <p>Loading course data...</p>
      )}
    </div>
  );
};

export default EditCoursePage;
