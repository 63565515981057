import React, { useState, useEffect } from "react";
import { Course } from "./interfaces";


const CourseCard = ({ course }: { course: Course }) => {
  return (
    <div className="min-w-60 max-w-80 h-full bg-white rounded-2xl shadow-md p-3 flex flex-col">
      <div className="flex items-center w-full">
      <img
        src={course.imageUrl}
        alt={course.title}
        className="w-full object-cover rounded-2xl mb-2"
      />
      </div>
  
      <div className="p-3">
        <div className="flex items-center mb-2">
      <img
          src={course.logo}
          alt={course.provider}
          className="w-12 h-12 rounded-xl border-2 shadow-xl border-gray-200 p-1 mr-2"
        />
        <p className="text-lg text-gray-400 font-medium">{course.provider}</p>
        </div>
      <h3 className="text-xl font-semibold mb-8">{course.title}</h3>
      <p className="relative bottom-0 text-lg text-gray-400 font-medium">{course.subtitle}</p>

      {course.isUpdated && (
        <span className="bg-blue-500 text-white px-2 py-1 rounded-full text-xs font-medium mt-2">
          Recently Updated
        </span>
      )}
      </div>
    </div>
  );
};

const CourseList = ({ courses }: { courses: Course[] }) => {
  return (
    <div className="container mx-auto px-4 py-4">
      <h1 className="text-xl md:text-3xl font-bold mb-4 text-center">
        See what you can learn with NAE Coaching Classes
      </h1>
      <div className="flex overflow-x-auto space-x-6 no-scrollbar py-3">
        {courses.map((course) => (
          <CourseCard key={course.title} course={course} />
        ))}
      </div>
      <div className="mt-8">
        <button className="bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300">
          Show 8 more
        </button>
      </div>
    </div>
  );
};


export default CourseList;
