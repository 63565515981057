import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Token ${token}`,
  },
});

interface CollectiveCourseFormProps {
  collectiveCourseId?: number;
}

interface Course {
  id: number;
  course_name: string;
}

interface Tag {
  id: number;
  name: string;
}

interface ClassOption {
  id: number;
  name: string;
}

const CollectiveCourseForm: React.FC<CollectiveCourseFormProps> = ({ collectiveCourseId }) => {
  const [collectiveCourseName, setCollectiveCourseName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number | string>('');
  const [discountPercentage, setDiscountPercentage] = useState<number | string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('Offline');
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [selectedClassFor, setSelectedClassFor] = useState<number[]>([]);
  const [collectiveCourseImg, setCollectiveCourseImg] = useState<File | null>(null);
  const [availableCourses, setAvailableCourses] = useState<Course[]>([]);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const [availableClasses, setAvailableClasses] = useState<ClassOption[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [addButtonOpen, setAddButtonOpen] = useState<Boolean>(false);

  useEffect(() => {
    // Fetch available courses, tags, and classes for selection
    const fetchOptions = async () => {
      try {
        const coursesResponse = await axiosInstance.get('/courses/courses/');
        const tagsResponse = await axiosInstance.get('/courses/tags/');
        const classesResponse = await axiosInstance.get('/courses/classes/');
        setAvailableCourses(coursesResponse.data);
        setAvailableTags(tagsResponse.data);
        setAvailableClasses(classesResponse.data);
      } catch (err) {
        setError('Error fetching form options.');
      }
    };

    fetchOptions();

    // If collectiveCourseId is provided, fetch existing collective course details
    if (collectiveCourseId) {
      const fetchCollectiveCourseData = async () => {
        try {
          const response = await axiosInstance.get(`/courses/collective-courses/${collectiveCourseId}/`);
          const data = response.data;

          setCollectiveCourseName(data.title);
          setDescription(data.description);
          setPrice(data.collective_price);
          setDiscountPercentage(data.discount_percentage);
          setSelectedCourses(data.courses.map((course: Course) => course));
          setSelectedTags(data.tags.map((tag: Tag) => tag));
          setSelectedClassFor(data.class_for.map((classId: number) => classId));
        } catch (err) {
          setError('Error fetching collective course data.');
        }
      };
      fetchCollectiveCourseData();
    }
  }, [collectiveCourseId]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCollectiveCourseImg(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const collectiveCourseData = {
      title: collectiveCourseName,
      description: description,
      collective_price: price,
      discount_percentage: discountPercentage,
      courses: selectedCourses,
      tags: selectedTags,
      class_for: selectedClassFor,
    };

    console.log(collectiveCourseData);
    

    try {
      let response;
      if (collectiveCourseId) {
        // Update existing collective course
        response = await axiosInstance.put(`/courses/collective-courses/${collectiveCourseId}/`, collectiveCourseData);
        setMessage('Collective Course updated successfully!');
      } else {
        // Create new collective course
        response = await axiosInstance.post('/courses/collective-courses/', collectiveCourseData);
        setMessage('Collective Course created successfully!');
      }

      // Handle file upload if collective course image is selected
      if (collectiveCourseImg) {
        const formData = new FormData();
        formData.append('title', response.data.title);
        formData.append('description', response.data.description);
        formData.append('collective_price', response.data.collective_price);
        formData.append('discount_percentage', response.data.discount_percentage);
        formData.append('courses', response.data.courses[0]);
        formData.append('tags', response.data.tags[0]);
        formData.append('class_for', response.data.class_for[0]);
        formData.append('course_img', collectiveCourseImg);
        await axiosInstance.put(`/courses/collective-courses-cu/${response.data.id || collectiveCourseId}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        response = await axiosInstance.put(`/courses/collective-courses/${response.data.id || collectiveCourseId}/`, collectiveCourseData);
        setMessage('Collective Course updated successfully!');
        
      }

      window.location.reload();

      setError(null);
    } catch (err) {
      setError('Error creating/updating collective course.');
      setMessage('');
    }
  };

  const toggleSection = () => {
    setAddButtonOpen(!addButtonOpen);
  };


  

  return (
    <div className="container mx-auto px-4 pb-4 flex justify-center items-center">
      <div className="w-full md:max-w-2xl">
        <div style={{ position: 'relative', cursor: 'pointer' }} className="rounded-xl bg-emerald-100 shadow-emerald-950 shadow-sm">
          <div className="rounded-xl shadow-emerald-100 shadow-md p-2 bg-emerald-50" onClick={toggleSection}>
            <div className="relative bottom-0 mt-2 w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between">
              <div className={`${collectiveCourseId ? 'bg-blue-500' : 'bg-purple-500'} rounded-xl px-4 text-lg text-white w-auto`}>
                {collectiveCourseId ? (addButtonOpen ? 'Close' : 'Update the Collective Course') : (addButtonOpen ? 'Close' : 'Add a New Collective Course')}
              </div>
            </div>
          </div>

          {addButtonOpen && (
            <div className="rounded-xl bg-blue-100 shadow-blue-500 shadow-sm">
              <div className="rounded-xl shadow-blue-100 shadow-md p-2 bg-blue-50">
                <h1 className="text-2xl font-bold mb-4">{collectiveCourseId ? 'Update Collective Course' : 'Create New Collective Course'}</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Collective Course Name</label>
                    <input
                      type="text"
                      value={collectiveCourseName}
                      onChange={(e) => setCollectiveCourseName(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Price</label>
                    <input
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Discount Percentage</label>
                    <input
                      type="number"
                      value={discountPercentage}
                      onChange={(e) => setDiscountPercentage(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Mode</label>
                    <select
                      value={mode}
                      onChange={(e) => setMode(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    >
                      <option value="Offline">Offline</option>
                      <option value="Online">Online</option>
                      <option value="Online & Offline">Online + Offline</option>
                    </select>
                  </div>

                  {/* Multi-select for Courses */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Courses</label>
                    <select
                    multiple
                    value={selectedCourses.map(String)} // Convert number[] to string[]
                    onChange={(e) =>
                      setSelectedCourses(Array.from(e.target.selectedOptions, (option) => Number(option.value)))
                    }
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  >
                    {availableCourses.map((course) => (
                      <option key={course.id} value={course.id}>
                        {course.course_name}
                      </option>
                    ))}
                  </select>
                  </div>

                  {/* Multi-select for Tags */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Tags</label>
                    <select
                      multiple
                      value={selectedTags.map(String)} // Convert number[] to string[]
                      onChange={(e) =>
                        setSelectedTags(Array.from(e.target.selectedOptions, (option) => Number(option.value)))
                      }
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    >
                      {availableTags.map((tag) => (
                        <option key={tag.id} value={tag.id}>
                          {tag.name}
                        </option>
                      ))}
                    </select>

                  </div>

                  {/* Multi-select for Class For */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Class For</label>
                    <select
                      multiple
                      value={selectedClassFor.map(String)} // Convert number[] to string[]
                      onChange={(e) =>
                        setSelectedClassFor(Array.from(e.target.selectedOptions, (option) => Number(option.value)))
                      }
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    >
                      {availableClasses.map((classOption) => (
                        <option key={classOption.id} value={classOption.id}>
                          {classOption.name}
                        </option>
                      ))}
                    </select>

                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Upload Image</label>
                    <input type="file" accept="image/*" onChange={handleFileChange} className="mt-1 block w-full" />
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-200"
                  >
                    {collectiveCourseId ? 'Update Collective Course' : 'Create Collective Course'}
                  </button>
                </form>

                {message && <p className="mt-4 text-green-500">{message}</p>}
                {error && <p className="mt-4 text-red-500">{error}</p>}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectiveCourseForm;
