import React, { useState } from 'react';
import axios from 'axios';

interface ChapterFormProps {
  courseId: number;
  chapterId?: number; // Optional for updating
}

const ChapterForm: React.FC<ChapterFormProps> = ({ courseId, chapterId }) => {
  const [title, setTitle] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [ytLinkRef, setYtLinkRef] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [addButtonOpen, setAddButtonOpen] = useState<Boolean>(false);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const chapterData = {
      title,
      is_completed: isCompleted,
      yt_link_ref: ytLinkRef,
      course: courseId
    };

    try {
      if (chapterId) {
        // Update chapter
        await axios.put(`${process.env.REACT_APP_API_URL}/courses/chapters/${chapterId}/`, chapterData);
        setMessage('Chapter updated successfully!');
      } else {
        // Create new chapter
        await axios.post(`${process.env.REACT_APP_API_URL}/courses/chapters/`, chapterData);
        setMessage('Chapter created successfully!');
      }
      window.location.reload();
      setError(null);
    } catch (err: any) {
      setError('Error creating/updating chapter.');
      setMessage(null);
    }
  };

  const toggleSection = () => {
    setAddButtonOpen(!addButtonOpen);
  };

  return (
    <div className="container mx-auto px-4 pb-4 flex justify-center items-center">
            <div className="w-full md:max-w-2xl">
              <div  style={{ position: 'relative', cursor: 'pointer' }} className=" rounded-xl bg-emerald-100 shadow-emerald-950 shadow-sm">
                <div className="rounded-xl shadow-emerald-100 shadow-md p-2 bg-emerald-50" onClick={toggleSection}>
                  <div className="relative bottom-0 mt-2 w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between">
                  <div
                    className={`${chapterId ? 'bg-green-500' : 'bg-purple-500'} rounded-xl px-4 text-lg text-white w-auto`}
                  >                      
                  {chapterId? (<>{addButtonOpen ? 'Close' : 'Update the Chapter'}</>) : (<>{addButtonOpen ? 'Close' : 'Add a New Chapter'}</>)}
                      
                    </div>
                  </div>
                </div>
                {addButtonOpen && (
                  <div className="rounded-xl shadow-sm bg-emerald-100 p-1">
                    
                    <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
                      <h1 className="text-2xl font-bold mb-4">{chapterId ? 'Update Chapter' : 'Create New Chapter'}</h1>

                      <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">Title</label>
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">YouTube Link (Optional)</label>
                          <input
                            type="url"
                            value={ytLinkRef || ''}
                            onChange={(e) => setYtLinkRef(e.target.value || null)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                          />
                        </div>

                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            checked={isCompleted}
                            onChange={() => setIsCompleted(!isCompleted)}
                            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                          <label className="ml-2 block text-sm text-gray-900">Mark as Completed</label>
                        </div>

                        <button
                          type="submit"
                          className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition"
                        >
                          {chapterId ? 'Update Chapter' : 'Create Chapter'}
                        </button>
                      </form>

                      {error && <p className="mt-4 text-red-500">{error}</p>}
                      {message && <p className="mt-4 text-green-500">{message}</p>}
                    </div>
                    <p className='text-center text-teal-600 text-lg font- font-semibold'>After upload refresh the page.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
  );
};

export default ChapterForm;
