// src/components/ui/scroll-area.tsx
import React, { forwardRef } from 'react';
import { cn } from '../../lib/utils';

interface ScrollAreaProps extends React.HTMLAttributes<HTMLDivElement> {}

const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={cn('overflow-auto', className)} {...props}>
      {children}
    </div>
  );
});

ScrollArea.displayName = 'ScrollArea';

export { ScrollArea };
