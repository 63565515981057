// src/components/Attendance.tsx
import React, { useState } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';

interface AttendanceProps {
  totalDays: number;
  attendedDays: number;
  attendance: Record<string, 'present' | 'absent' | 'holiday'>;
}

const Attendance: React.FC<AttendanceProps> = ({ totalDays, attendedDays, attendance }) => {
  const [showDetails, setShowDetails] = useState(false);

  // Generate calendar for current month
  const currentMonth = new Date();
  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(currentMonth),
    end: endOfMonth(currentMonth),
  });

  const renderCalendar = () => (
    <div className="grid grid-cols-7 gap-1">
      {daysInMonth.map((day: Date) => {
        const dayString = format(day, 'yyyy-MM-dd');
        const attendanceStatus = attendance[dayString];

        let bgColor = 'bg-gray-200'; // Default color for days with no data
        if (attendanceStatus === 'present') bgColor = 'bg-green-500';
        if (attendanceStatus === 'absent') bgColor = 'bg-red-500';
        if (attendanceStatus === 'holiday') bgColor = 'bg-blue-500';

        return (
          <div
            key={dayString}
            className={`p-2 text-white rounded ${bgColor} text-center`}
          >
            {format(day, 'd')}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md mb-6">
      <h2 className="text-xl font-semibold mb-2">Attendance</h2>
      <p className="text-gray-700">
        Days Attended: <span className="font-bold">{attendedDays}</span> /{' '}
        <span className="font-bold">{totalDays}</span>
      </p>
      <div className="w-full bg-gray-300 rounded-full h-4 mt-2">
        <div
          className="bg-blue-600 h-4 rounded-full"
          style={{ width: `${(attendedDays / totalDays) * 100}%` }}
        ></div>
      </div>

      <button
        className="mt-4 text-blue-500 hover:underline"
        onClick={() => setShowDetails(!showDetails)}
      >
        {showDetails ? 'Hide Details' : 'More'}
      </button>

      {showDetails && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Attendance Calendar:</h3>
          {renderCalendar()}
        </div>
      )}
    </div>
  );
};

export default Attendance;
