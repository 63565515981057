import React, { useState, useRef, useEffect } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { useParams } from 'react-router-dom';

// Set the worker path (adjust if needed)
GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${require('pdfjs-dist/package.json').version}/build/pdf.worker.min.js`;

const PdfReader: React.FC = () => {
  const { fileUrl } = useParams<{ fileUrl?: string }>();

  // Ensure fileUrl is defined and fallback if not
  const decodedFileUrl = fileUrl ? decodeURIComponent(fileUrl) : '';

  const [pdfDoc, setPdfDoc] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pages, setPages] = useState<string[]>([]);
  const [transitioning, setTransitioning] = useState(false);
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!decodedFileUrl) return; // If no fileUrl, do nothing

    const fetchPdf = async () => {
      const response = await fetch(decodedFileUrl);
      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();
      const pdf = await getDocument({ data: arrayBuffer }).promise;
      setPdfDoc(pdf);
      setTotalPages(pdf.numPages);

      // Load all pages as images
      const pageImages: string[] = [];
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return;

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport,
        };

        await page.render(renderContext).promise;
        pageImages.push(canvas.toDataURL());
      }
      setPages(pageImages);
    };

    fetchPdf();
  }, [decodedFileUrl]);

  const handlePageChange = (direction: 'prev' | 'next') => {
    if (transitioning) return;
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
    }, 300); // Duration should match the CSS transition duration

    if (direction === 'prev') {
      setCurrentPage(prev => Math.max(prev - 1, 1));
    } else if (direction === 'next') {
      setCurrentPage(prev => Math.min(prev + 1, totalPages));
    }
  };

  const handleFullScreen = () => {
    if (pageRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        pageRef.current.requestFullscreen();
      }
    }
  };

  return (
    <div className="pdf-reader p-4 mx-auto max-w-4xl border border-gray-300 rounded-lg shadow-lg bg-white" ref={pageRef}>
      <div className="flex justify-between items-center mb-4 border-b border-gray-200 pb-2">
        <div className="text-gray-700">
          Page {currentPage} of {totalPages}
        </div>
        <div className="space-x-2">
          <button
            className="px-3 py-1 bg-blue-500 text-white rounded disabled:bg-blue-300"
            onClick={() => handlePageChange('prev')}
            disabled={currentPage === 1}
          >
            {'<'}
          </button>
          <button
            className="px-3 py-1 bg-blue-500 text-white rounded disabled:bg-blue-300"
            onClick={() => handlePageChange('next')}
            disabled={currentPage === totalPages}
          >
            {'>'}
          </button>
          <button
            className="px-3 py-1 bg-green-500 text-white rounded"
            onClick={handleFullScreen}
          >
            {document.fullscreenElement ? 'Exit Fullscreen' : 'Fullscreen'}
          </button>
        </div>
      </div>
      <div className="relative h-screen overflow-y-auto no-scrollbar">
        <div
          className={`transition-transform duration-300 ease-in-out ${transitioning ? 'opacity-50' : ''}`}
          style={{ transform: `translateX(-${(currentPage - 1) * 100}%)` }}
        >
          {pages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Page ${index + 1}`}
              className="w-full h-auto inline-block"
              style={{ maxHeight: '80vh' }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PdfReader;
