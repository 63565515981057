import React, { useState, useEffect } from 'react';
import { Physics11Syllab } from './SyllabusTree';

interface Course {
  title: string;
  curret_lesson: string;
  imgUrl: string;
  tprogress: number;
  sprogress: number;
}

const coursesData: Course[] = [
  {
    title: 'UI Design',
    curret_lesson: 'Chapter 2. Motion',
    imgUrl: '/assets/naescilogo.jpeg',
    tprogress: 60,
    sprogress: 50,
  },
  {
    title: 'Basics Programming',
    curret_lesson: 'chapter 3. Atoms',
    imgUrl: '/assets/naescilogo.jpeg',
    tprogress: 80,
    sprogress: 75,
  },
  {
    title: 'UI Design2',
    curret_lesson: 'Chapter 2. Motion',
    imgUrl: '/assets/naescilogo.jpeg',
    tprogress: 60,
    sprogress: 50,
  },
  {
    title: 'Basics Programming2',
    curret_lesson: 'chapter 3. Atomic structure',
    imgUrl: '/assets/naescilogo.jpeg',
    tprogress: 80,
    sprogress: 75,
  },
  {
    title: 'UI Design3',
    curret_lesson: 'Chapter 2. Motion',
    imgUrl: '/assets/naescilogo.jpeg',
    tprogress: 60,
    sprogress: 50,
  },
  {
    title: 'Basics Programming3',
    curret_lesson: 'chapter 3. Atoms',
    imgUrl: '/assets/naescilogo.jpeg',
    tprogress: 80,
    sprogress: 75,
  },
  {
    title: 'UI Design4',
    curret_lesson: 'Chapter 2. Motion',
    imgUrl: '/assets/naescilogo.jpeg',
    tprogress: 60,
    sprogress: 50,
  },
  {
    title: 'Basics Programming4',
    curret_lesson: 'chapter 3. Atoms',
    imgUrl: '/assets/naescilogo.jpeg',
    tprogress: 80,
    sprogress: 75,
  },
];


const ContinueCourse: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [expandedCourses, setExpandedCourses] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setCourses(coursesData);
  }, []);

  const toggleExpand = (title: string) => {
    setExpandedCourses((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl lg:text-3xl font-bold mb-4">Continue Course</h2>
      
      <div className="w-full flex flex-wrap w-max-6xl justify-center gap-8 lg:gap-12">
        {courses.map((course) => (
          <div
            key={course.title}
            className="w-full md:w-1/2 lg:w-1/3 bg-white rounded-xl lg:rounded-2xl shadow-md p-4 "
          >
            <div className='flex'>
            <div>
            <img
              src={course.imgUrl}
              className="w-20 h-20 md:w-24 md:h-24 rounded-xp-1 mr-2 object-cover"
            />
            </div>
            <div className='p-2 w-full'>
            <h3 className="text-xl font-bold mb-2">{course.title}</h3>
            <p className="text-gray-700 mb-4 font-bold">Ongoing: {course.curret_lesson}</p>
            
            <div className="flex w-full items-center bg-gray-200 rounded-full mb-2" >
              <div
                className="bg-green-400 rounded-full h-4 w-full text-[10px] text-center"
                style={{ width: `${course.tprogress}%` }}
              >
                <span className="text-end text-gray-700 text-[12px]">{course.tprogress}%</span>
              </div>
            
            </div>

            <div className="flex items-center bg-gray-200 rounded-full" >
              <div
                className="bg-yellow-400 rounded-full h-4 w-full text-[10px] text-center"
                style={{ width: `${course.sprogress}%` }}
              >
                <span className="text-end text-gray-700 text-[12px]">{course.sprogress}%</span>
              </div>
            
            </div>
            </div>
            </div>
            {expandedCourses[course.title] && (
              <div className="mt-4">
                <Physics11Syllab subject="Physics" />
              </div>
            )}
            <div className='flex justify-between'>
            <button
              className="mt-4 px-4 py-1 bg-blue-500 rounded-full text-white"
              onClick={() => toggleExpand(course.title)}
            >
              {expandedCourses[course.title] ? 'Minimize' : 'Expand'}
            </button>
            <button
              className=" mt-4 px-4 py-1 bg-green-900 rounded-full text-white">
             Go to Course
            </button>
            </div>
            
          </div>

        ))}
      </div>

    </div>
  );
};

export default ContinueCourse;
