// src/components/Fees.tsx
import React, { useState } from 'react';

interface PaymentDetail {
  date: string;
  amount: number;
}

interface FeesProps {
  totalInstallments: number;
  paidInstallments: number;
  payments: PaymentDetail[];
  totalAmount: number;
  remainingAmount: number;
}

const Fees: React.FC<FeesProps> = ({
  totalInstallments,
  paidInstallments,
  payments,
  totalAmount,
  remainingAmount,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const remainingInstallments = totalInstallments - paidInstallments;

  return (
    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
      <h2 className="text-xl font-semibold mb-2">Fees</h2>
      <p className="text-gray-700">
        Installments Paid: <span className="font-bold">{paidInstallments}</span> /{' '}
        <span className="font-bold">{totalInstallments}</span>
      </p>
      <p className="text-gray-700">
        Installments Left: <span className="font-bold">{remainingInstallments}</span>
      </p>
      <p className="text-gray-700">
        Paid Amount: <span className="font-bold">₹{totalAmount - remainingAmount}</span> /{' '}
        <span className="font-bold">₹{totalAmount}</span>
      </p>
      <div className="w-full bg-gray-300 rounded-full h-4 mt-2">
        <div
          className="bg-green-600 h-4 rounded-full"
          style={{ width: `${(paidInstallments / totalInstallments) * 100}%` }}
        ></div>
      </div>

      <button
        className="mt-4 text-blue-500 hover:underline"
        onClick={() => setShowDetails(!showDetails)}
      >
        {showDetails ? 'Hide Details' : 'More'}
      </button>

      {showDetails && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Payment Details:</h3>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            {payments.map((payment, index) => (
              <li key={index}>
                Date: {payment.date}, Amount: ₹{payment.amount}
              </li>
            ))}
          </ul>
          <p className="text-gray-700 mt-4">
            Remaining Amount: <span className="font-bold">₹{remainingAmount}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default Fees;
