import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface YouTubeVideoProps {
  url?: string;
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = () => {
  const { videoId } = useParams<{ videoId: string }>();
  const [url, setUrl] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (videoId) {
      setUrl(`https://www.youtube.com/watch?v=${videoId}`);
    } else {
      setUrl("https://www.youtube.com/watch?v=dQw4w9WgXcQ");
    }
  }, [videoId]);

  const startTime = url.includes('t=') ? parseInt(url.split('t=')[1]) : 0;

  return (
    <div
      className="w-full h-0 relative"
      style={{ paddingBottom: '56.25%' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={`https://www.youtube.com/embed/${videoId}?start=${startTime}&rel=0&modestbranding=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      {isHovered && (
        <>
          <div className="absolute top-0 left-0 w-full h-24 flex items-center justify-center bg-black bg-opacity-50 text-white text-xl">
            NAE COACHING
          </div>
          <div className="absolute bottom-0 left-0 w-full h-24 flex items-center justify-center bg-black bg-opacity-50 text-white text-xl">
            NAE COACHING
          </div>
        </>
      )}
    </div>
  );
};

export default YouTubeVideo;
