import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Login: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1); // Step tracking
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleNext = async () => {
    if (step === 1 && phoneNumber) {
      setStep(2);
    } else if (step === 2 && password) {
      await handleLogin();
    } else {
      setError('Please fill in all fields.');
    }
  };

  const handleLogin = async () => {
    try {
      await login(phoneNumber, password);
      navigate('/');
    } catch (error) {
      console.log('Login failed: An unknown error occurred.');
      
      setError('Login failed: An unknown error occurred.');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-6 rounded-2xl shadow-2xl w-full max-w-md">
        <h2 className="text-2xl mb-4 font-bold">Login</h2>
        {step === 1 && (
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Phone Number</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
          </div>
        )}
        {step === 2 && (
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-xl shadow-md focus:outline-none"
            />
          </div>
        )}
        {step === 3 && (
          <div className="mb-4">
            <p className="text-green-700 text-lg font-semibold">{error}</p>
            <p className="text-gray-700">Redirecting...</p>
          </div>
        )}
        <div className='flex w-full justify-end'>
          <button
            type="button"
            onClick={handleNext}
            className="w-20 bg-blue-500 text-white py-2 rounded-xl hover:bg-blue-600"
          >
            {step === 2 ? 'Login' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
