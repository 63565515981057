// src/components/Courses.tsx
import React from 'react';

interface Course {
  name: string;
  status: 'completed' | 'in-progress';
}

interface CoursesProps {
  courses: Course[];
}

const Courses: React.FC<CoursesProps> = ({ courses }) => {
  const completedCourses = courses.filter(course => course.status === 'completed');
  const inProgressCourses = courses.filter(course => course.status === 'in-progress');

  return (
    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md mb-6">
      <h2 className="text-xl font-semibold mb-4">Courses</h2>

      <div className="mb-4">
        <h3 className="text-lg font-semibold">Completed Courses</h3>
        {completedCourses.length > 0 ? (
          <ul className="list-disc list-inside text-gray-700 mt-2">
            {completedCourses.map((course, index) => (
              <li key={index}>{course.name}</li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No completed courses.</p>
        )}
      </div>

      <div>
        <h3 className="text-lg font-semibold">Courses In Progress</h3>
        {inProgressCourses.length > 0 ? (
          <ul className="list-disc list-inside text-gray-700 mt-2">
            {inProgressCourses.map((course, index) => (
              <li key={index}>{course.name}</li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No courses in progress.</p>
        )}
      </div>
    </div>
  );
};

export default Courses;
