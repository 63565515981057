import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Token ${token}`,
  },
});

interface CourseFormProps {
  courseId?: number;
}

interface Teacher {
  id: number;
  user: {
    first_name: string;
    last_name: string;
  };
}

const CourseForm: React.FC<CourseFormProps> = ({ courseId }) => {
  const [courseName, setCourseName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number | string>('');
  const [discountPercentage, setDiscountPercentage] = useState<number | string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('Offline');
  const [selectedClasses, setSelectedClasses] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [courseImg, setCourseImg] = useState<File | null>(null);
  const [availableClasses, setAvailableClasses] = useState<any[]>([]);
  const [availableTags, setAvailableTags] = useState<any[]>([]);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [teacher, setTeacher] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [addButtonOpen, setAddButtonOpen] = useState<Boolean>(false);
  const [newClass, setNewClass] = useState('');
  const [newTag, setNewTag] = useState('');

  useEffect(() => {

    
    // Fetch available classes, tags, and teachers for selection
    const fetchData = async () => {
      try {
        const [classesResponse, tagsResponse, teachersResponse] = await Promise.all([
          axiosInstance.get('/courses/classes/'),
          axiosInstance.get('/courses/tags/'),
          axiosInstance.get('/users/teachers/'),
        ]);

        setAvailableClasses(classesResponse.data);
        setAvailableTags(tagsResponse.data);
        setTeachers(teachersResponse.data);
        
      } catch (err) {
        setError('Error fetching data.');
      }
    };
 
    fetchData();

    // If courseId is provided, fetch existing course details
    if (courseId) {
      const fetchCourseData = async () => {
        try {
          const response = await axiosInstance.get(`/courses/courses/${courseId}/`);
          const data = response.data;
          
          setCourseName(data.course_name);
          setDescription(data.description);
          setPrice(data.price);
          setDiscountPercentage(data.discount_percentage);
          setIsCompleted(data.is_completed);
          setMode(data.mode);
          setSelectedClasses(data.class_for.map((c: any) => c.id));
          setSelectedTags(data.tags.map((t: any) => t.id));
          setTeacher(data.teacher.id);
        } catch (err) {
          setError('Error fetching course data.');
        }
      };
      fetchCourseData();
    }
  }, [courseId]);

  const handleAddNewClass = () => {
    if (newClass.trim()) {
      axiosInstance.post('courses/classes/', { name: newClass })
        .then(response => {
          setAvailableClasses([...availableClasses, response.data]);
          setNewClass('');
          // setChange(!change);
        })
        .catch(error => {
          console.error('Error adding class:', error);
        });
    }
  };

  const handleAddNewTag = () => {
    if (newTag.trim()) {
      axiosInstance.post('courses/tags/', { name: newTag })
        .then(response => {
          setAvailableTags([...availableTags, response.data]);
          setNewTag('');
          // setChange(!change);
        })
        .catch(error => {
          console.error('Error adding tag:', error);
        });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCourseImg(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if(teacher === 0){
      alert('Select Teacher');
      return;
    }

    const courseData = {
      course_name: courseName,
      description: description,
      price: price,
      discount_percentage: discountPercentage,
      is_completed: isCompleted,
      mode: mode,
      class_for: selectedClasses,
      tags: selectedTags,
      teacher: teacher,
    };

    console.log(courseData);
    

    
    try {
      let response;
      if (courseId) {
        // Update existing course
        response = await axiosInstance.put(`/courses/coursecu/${courseId}/`, courseData);
        setMessage('Course updated successfully!');
      } else {
        // Create new course        
        response = await axiosInstance.post('/courses/coursecu/', courseData);
        setMessage('Course created successfully!');
      }
      

      // Handle file upload if course image is selected
      if (courseImg) {
        const formData = new FormData();
        formData.append('course_name', response.data.course_name);
        formData.append('description', response.data.description);
        formData.append('price', response.data.price);
        formData.append('discount_percentage', response.data.discount_percentage);
        formData.append('is_completed', response.data.is_completed);
        formData.append('mode', response.data.mode);
        formData.append('class_for', response.data.class_for[0]);
        formData.append('tags', response.data.tags[0]);
        formData.append('teacher', response.data.teacher);
        formData.append('course_img', courseImg);
        await axiosInstance.put(`/courses/coursecu/${response.data.id || courseId}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        response = await axiosInstance.put(`/courses/coursecu/${response.data.id || courseId}/`, courseData);
        setMessage('Course updated successfully!');

      }

      window.location.reload();

      setError(null);
    } catch (err) {
      setError('Error creating/updating course.');
      setMessage('');
    }
  };

  const toggleSection = () => {
    setAddButtonOpen(!addButtonOpen);
  };

  return (
    <div className="container mx-auto px-4 pb-4 flex justify-center items-center">
      <div className="w-full md:max-w-2xl">
        <div style={{ position: 'relative', cursor: 'pointer' }} className="rounded-xl bg-emerald-100 shadow-emerald-950 shadow-sm">
          <div className="rounded-xl shadow-emerald-100 shadow-md p-2 bg-emerald-50" onClick={toggleSection}>
            <div className="relative bottom-0 mt-2 w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between">
              <div className={`${courseId ? 'bg-blue-500' : 'bg-purple-500'} rounded-xl px-4 text-lg text-white w-auto`}>
                {courseId ? (addButtonOpen ? 'Close' : 'Update the Course') : (addButtonOpen ? 'Close' : 'Add a New Course')}
              </div>
            </div>
          </div>

          {addButtonOpen && (
            <div className="rounded-xl bg-blue-100 shadow-blue-500 shadow-sm">
              <div className="rounded-xl shadow-blue-100 shadow-md p-2 bg-blue-50">
                <h1 className="text-2xl font-bold mb-4">{courseId ? 'Update Course' : 'Create New Course'}</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Course Name</label>
                    <input
                      type="text"
                      value={courseName}
                      onChange={(e) => setCourseName(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Price</label>
                    <input
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Discount Percentage</label>
                    <input
                      type="number"
                      value={discountPercentage}
                      onChange={(e) => setDiscountPercentage(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Mode</label>
                    <select
                      value={mode}
                      onChange={(e) => setMode(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    >
                      <option value="Offline">Offline</option>
                      <option value="Online">Online</option>
                      <option value="Online & Offline">Online + Offline</option>
                    </select>
                    
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Is Completed?</label>
                    <input
                      type="checkbox"
                      checked={isCompleted}
                      onChange={(e) => setIsCompleted(e.target.checked)}
                      className="mt-1"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Select Classes</label>
                    <select
                      multiple
                      value={selectedClasses.map(String)}
                      onChange={(e) => setSelectedClasses(Array.from(e.target.selectedOptions, option => parseInt(option.value)))}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    >
                      {availableClasses.map((cls) => (
                        <option key={cls.id} value={cls.id}>
                          {cls.name}
                        </option>
                      ))}
                    </select>

                    <div className="flex mt-2">
                      <input
                        type="text"
                        value={newClass}
                        onChange={(e) => setNewClass(e.target.value)}
                        placeholder="New class name"
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                      <button type="button" onClick={handleAddNewClass} className="bg-green-500 text-white px-4 py-2 rounded-lg ml-2">
                        Add Class
                      </button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Select Tags</label>
                    <select
                      multiple
                      value={selectedTags.map(String)}
                      onChange={(e) => setSelectedTags(Array.from(e.target.selectedOptions, option => parseInt(option.value)))}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    >
                      {availableTags.map((tag) => (
                        <option key={tag.id} value={tag.id}>
                          {tag.name}
                        </option>
                      ))}
                    </select>

                    <div className="flex mt-2">
                      <input
                        type="text"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        placeholder="New tag name"
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                      <button type="button" onClick={handleAddNewTag} className="bg-green-500 text-white px-4 py-2 rounded-lg ml-2">
                        Add Tag
                      </button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Select Teacher</label>
                    <select
                      value={teacher}
                      onChange={(e) => setTeacher(parseInt(e.target.value))}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    >
                        <option value={0}>Select Teacher</option>
                      {teachers.map((tch) => (
                        <option key={tch.id} value={tch.id}>
                          {tch.user.first_name} {tch.user.last_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Course Image</label>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>

                  <div>
                    <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-700">
                      {courseId ? 'Update Course' : 'Create Course'}
                    </button>
                  </div>
                </form>
                {message && <p className="mt-4 text-green-600">{message}</p>}
                {error && <p className="mt-4 text-red-600">{error}</p>}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseForm;
