// import { Physics11Syllab } from '../components/SyllabusTree';
import ContinueCourse from '../components/ContinueCourses';
import React from 'react';

const Progress: React.FC = () => {
  return (
    <div>
      <div>
        <ContinueCourse />
      </div>

      
      
    </div>
  );
};

export default Progress;
